.c3-axis-x .tick,
.c3-axis-y .tick,
.c3-axis-y2 .tick  {
  fill:$gray-light;
}
.c3-axis-x-label,
.c3-axis-y-label,
.c3-axis-y2-label {
  fill:$gray-light;
}

.trace-chart .tick {
  display:none;
}