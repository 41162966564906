.analyze-loader-wrapper {

  &.ng-hide-add {
    animation: fadeOut .5s;
    position: absolute;
    width:100%;
    height:calc(100% - 100px);
    left:0;
    right:0;
    overflow:hidden;
    z-index:-1;
  }

  .analyze-loader {
    padding-top:calc( #{$navbar-height} + #{$navbar-margin-bottom} + 2px);
  } 

  .well {
    hr {
      opacity:.75;
    }
     p.lead {
      max-width:460px;
      margin-left:auto;
      margin-right:auto;
    }
  }

}

#loader-bg,
#loader-bg-sine {
  position:absolute;
  z-index:-1;
  left:0;
  right:0;
  top:0;
  bottom:0;
  height:100vh;
  width:100vw;
  overflow:hidden;
  opacity:.75;
}

.progress.thin {
  height:4px;

  &.thin-top {
    border-radius:$progress-border-radius $progress-border-radius 0 0;
    margin:0 0 -4px 0;
    position:relative;
    z-index:100;
  }
}

.analysis-progress {
  height:50px;
  display:flex;
  align-items:center;
  position:relative;
  overflow:hidden;

  &:before {
    content:'';
    position:absolute;
    background: linear-gradient(to right, rgba(#011c23,1) 0%, rgba(#011c23,0) 100%);
    height:100%;
    width:129px;
    top:0;
    left:0;
    z-index:1;
  }

  .analysis-progress-inner {
    display:flex;
    position: absolute;
    right: 0;
    min-width:100%;
    align-items:center;
    justify-content: center;

    span {
      display:block;
      position:relative;
      transition:all .3s ease-in-out;
      overflow:hidden;

      .status-icon {
        position:absolute;
        background:#303030;
        border-radius:100px;
        box-shadow: inset 0px 0px 0px 4px #011c23, 0px 0px 0px 1px #011c23;
      }
      &.ng-enter {
        width:0px;
        transform:scale(0);
      }
      &.ng-enter-active {
        width:48px;
        transform:scale(1);
      }
      &.ng-enter-stagger {
        transition-delay: .1s;
        transition-duration: 0s;
      }
    }
  }
}

.analysis-progress-vertical {
  height:192px;
  display:flex;
  align-items:center;
  position:relative;

  &:before {
    content:'';
    position:absolute;
    background: linear-gradient(to bottom, rgba(#011c23,1) 0%, rgba(#011c23,0) 100%);
    height:128px;
    width:100%;
    top:0;
    z-index:1;
  }

  .analysis-progress-inner {
    width:100%;
    span {
      display:block;
      width:100%;
      position:relative;
      font-size:18px;
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
      text-align:left;

      .status-icon {
        position:absolute;
        background:#303030;
        border-radius:100px;
        box-shadow: inset 0px 0px 0px 4px #011c23, 0px 0px 0px 1px #011c23;
      }

    }
  }
}