//Multiplex customizations

.multiplex-select {

  @for $i from 0 through 50 {
    &.guide-error-#{$i} {
      .ui-select-match span:nth-child(#{$i+1}) .ui-select-match-item {
        border-color:$brand-danger!important;
      }
    }
  }

  > div:first-child {
    display:flex;
    flex-wrap:wrap;
  }

  .ui-select-match {
    display:flex;
    flex-wrap:wrap;

    .ui-select-match-item {
      @extend .mono;
      font-size:16px;
      white-space:normal;
      text-align:left;
      padding:7px 5px 7px 15px;
      border-color:$brand-success;
      display: flex;
      flex-direction: row-reverse;
      align-items:center;
      &:hover, &:focus {
        border-color:$brand-success!important;
      }
      span {
        word-break:break-all;
        display:inline-block;
        line-height:1em;
        &.close {
          line-height:1em;
        }
      }
    }
  }
  .ui-select-search {
    width:auto!important;
    flex-grow:1;
    &.ng-not-empty {
      @extend .mono;
    }
  }
  //Dirty dirty hacks
  //https://github.com/angular-ui/ui-select/issues/1962

  .ui-select-choices {
      border-radius:$border-radius-base;
      background:$tooltip-bg;
      pointer-events: none;
      box-shadow: none;
      position: absolute;
      right: 0;
      width: auto;
      height: 24px;
      left: initial;
      margin-top: -10px;
    .ui-select-choices-row {
      @extend .all-caps;
      font-size:11px;

      &.active>span {
        color:#fff;
        background:transparent!important;
      }
      >span {
        color:#fff!important;
      }
    }
  }
}

.label {
  &.label-g1 {background:$gray}
  &.label-g2 {background:lighten($gray, 15%)}
  &.label-g3 {background:lighten($gray, 30%)}
  &.label-dropout {background:#fff; color:$gray;}
  &.label-hdr {background:#fff; color:$gray;}
}