.c3-region {
  fill: steelblue;
  fill-opacity: .1;

  &.guide {
    fill: #D6C600;
  }
  &.pam {
    fill: $brand-warning;
  }
  &.alignment {
    fill: lighten($brand-danger, 15%);
  }
  &.inference {
    fill: lighten($brand-warning, 15%);
  }
}
