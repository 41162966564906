.c3-tooltip-container {
  z-index: 10;
  border-radius:$border-radius-base;
  background-color: $gray;
}
.c3-tooltip {
  border-collapse:collapse;
  border-spacing:0;
  empty-cells:show;
}
.c3-tooltip tr {
  border-bottom:1px solid $gray-light;
}
.c3-tooltip th {
  font-size:14px;
  padding:2px 5px;
  text-align:left;
  color:#fff;
}
.c3-tooltip td {
  font-size:13px;
  padding: 3px 6px;
  color:#fff;
}
.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
.c3-tooltip td.value{
  text-align: right;
}
