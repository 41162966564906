.scrollable {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.scrollable-content {
  box-sizing: border-box;
  min-height: 100%;
}

/* Enable the Classes below to have scrollbars not overlap the content */
/*
.scrollable-content.scrollable-right {
    margin-right: 15px;
}

.scrollable-content.scrollable-top {
    margin-top: 15px;
}

.scrollable-content.scrollable-left {
    margin-left: 15px;
}

.scrollable-content.scrollable-bottom {
    margin-bottom: 15px;
}
*/

/* if any element is fixed and displayed at full window size, this rule becomes handy */

.fullwindow .scrollable-content {
  transform: none !important;
}

.scrollable {
  .scrollable-content, .scrollable-slider {
    transition: background-color .2s linear, opacity .2s linear, transform .2s ease-out;
  }
  &.active {
    .scrollable-content, .scrollable-slider {
      transition: none;
    }
  }
  .scrollable-bar {
    position: absolute;
    box-sizing: border-box;
    border-radius: 0;
    opacity: 0;
    background-color: #f0f0f0;
    background-color: rgba(255, 255, 255, 0.8);
    border: 2px solid transparent;
  }
  &:hover .scrollable-bar {
    opacity: 0.6;
  }
  .scrollable-bar:hover, &.active .scrollable-bar {
    opacity: 1;
  }
  .scrollable-slider {
    border-radius: 4px;
    background-color: rgba(0,0,0,.25);
  }
  .scrollable-bar-x .scrollable-slider {
    height: 8px;
  }
  .scrollable-bar-y .scrollable-slider {
    width: 8px;
  }
}