.c3 svg {
  font: 10px sans-serif;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.c3 path, .c3 line {
  fill: none;
  stroke: $gray-light;
}
.c3 text {
  user-select: none;
  text-transform:uppercase;
  letter-spacing:1px;
  font-family:$font-family-base;

}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc path {
  stroke: #fff;

}
.c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}
