$color-a: $brand-primary;
$color-c: $brand-info;
$color-g: $gray-dark;
$color-t: $brand-danger;

$color-a-lighter: lighten($color-a, 45%);
$color-c-lighter: lighten($color-c, 50%);
$color-g-lighter: lighten($gray-lighter, 5%);
$color-t-lighter: lighten($color-t, 55%);

$color-a-light: lighten($color-a, 40%);
$color-c-light: lighten($color-c, 45%);
$color-g-light: $gray-lighter;
$color-t-light: lighten($color-t, 50%);

$color-inserted-lighter: lighten($brand-warning, 25%);
$color-inserted-light: lighten($brand-warning, 15%);

@mixin base {
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  letter-spacing:0;

  &.base-A {
    color: $color-a;
    background-color: $color-a-lighter;
  } 
  &.base-C {
    color: $color-c;
    background-color: $color-c-lighter;
  }
  &.base-G {
    color: $color-g;
    background-color: $color-g-lighter;
  }
  &.base-T {
    color: $color-t;
    background-color: $color-t-lighter;
  }
  &.base--, &.base-N {
    color:$gray-light;
  }
  &.base-a, &.base-c, &.base-g, &.base-t {
    color: rgba($gray-darker, .75);
    background-color: $color-inserted-lighter;
  }  
}

#summary-chart {
  overflow:hidden;
  
  &.hide-tick-text {
    .c3-axis-x {
      .tick {
        text {
          display:none!important;
        }
      }
    }
  }
  .c3-event-rects {
    fill-opacity:1!important;
    .c3-event-rect {
      fill-opacity:0;
      &:hover {
        fill-opacity:1;
        fill:$gray-lighter;
        opacity:.35;
        cursor:pointer;
      }
    }
  }
}

#discord-chart {
  .tick {
    line {
      display:none;
    }
    &:nth-child(5n) {
      line {
        display:block;
      }
    }
  }
  .rsquared {
    line {
      display:none;
    }
  }
}

#trace-chart {
  overflow-x:auto;
  min-height:323px;
  
  svg {
    width:1310px;
    height:auto;
    text {
      font-size:8px;
      fill:$gray;
      letter-spacing:1px;
      text-transform:uppercase;
    }
    path {
      stroke-width:.5;
    }
    g {
      &.subplot_title {
        text {
          fill:$gray-darker;
          font-weight:bold;
        }
      }
      &.cutsite_line {
        path {
          stroke:$gray-darker!important;
          stroke-width:1!important;
        }
      }
      &.bp_coord {
        font-size:10px;
      }
      &[class^="basecall_"] {
        @extend .mono;
        transform: translateX(-3px);
        text {
          fill:$gray;
        }
      }
      &.basecall_C {
        text {
          fill:$color-c;
        }
      }
      &.basecall_T {
        text {
          fill:$color-t;
        }
      }
      &.basecall_G {
        text {
          fill:$color-g;
        }
      }
      &.basecall_A {
        text {
          fill:$color-a;
        }
      }
      &.trace_C {
        path {
          stroke:$color-c!important;
        }
      }
      &.trace_T {
        path {
          stroke:$color-t!important;
        }
      }
      &.trace_G {
        path {
          stroke:$color-g!important;
        }
      }
      &.trace_A {
        path {
          stroke:$color-a!important;
        }
      }
    } 
  }
}

.alignment-chart {

  position:relative;
  
  .legend {
    position:absolute;
    z-index:1;
    width:195px;
    overflow:visible;

    .spacer {
      height:30px;
    }
    .title {
      @extend .all-caps;
      font-weight:bold;
      color:$gray-darker;
      white-space:nowrap;
      height:20px;
    }

    .labels {
      @extend .all-caps;
      font-size:11px;
      color:$gray;

      .btn-hover {
        visibility:hidden;
      }

      &:hover {
        .btn-hover {
          visibility:visible;
        }
      }

      > div {
        height:16px;
        display:flex;
        align-items:center;
        justify-content: space-between;
        padding-right:15px;

        //&:not(:empty) {
          background:#fff;
        //}
      }
    }
  
  }
  .alignment {
    display:flex;
    padding:20px 0 30px 0;
    position:relative;

    .sequences {
      display:flex;
      flex-direction:row;
      margin-left:195px;

      .col {
        display:flex;
        flex-direction:column;
        &-item {
          width:16px;
          height:16px;
          font-size:12px;

          display:flex;
          align-items:center;
          justify-content:center;

          transition:transform .1s linear;

          /*&:nth-child(-n+100) {
            &.ng-enter {
              transform:rotateX(90deg);
            }
            &.ng-enter-active {
              transform:rotateX(0deg);
            }
            &.ng-enter-stagger {
              transition-delay: .05s;
              transition-duration: 0s;
            }
          }*/

          .match {
            width:1px;
            height:100%;
            background-color:$gray-lighter;

            &.match-A {
              background-color: $color-a;
            } 
            &.match-C {
              background-color: $color-c;
            }
            &.match-G {
              background-color: $color-g;
            }
            &.match-T {
              background-color: $color-t;
            }
          }
          .base {
            @extend .mono;
            @include base;
          }
        }
      }
    }
  }

  .scrollable {
    padding-bottom:40px;

    .scrollable-content, .scrollable-slider {
      transition: background-color .2s linear, opacity .2s linear, transform .2s ease-out;
    }
    .scrollable-bar {
      transition: all .2s linear;
    }
    &.active {
      .scrollable-content, .scrollable-slider {
        transition: none;
      }
    }
    .scrollable-bar {
      position: absolute;
      box-sizing: border-box;
      border-radius: 0;
      opacity:1;
      background-color: rgba(255, 255, 255, .5);
      border: 2px solid transparent;
    }
    &:hover .scrollable-bar {
      opacity:1;
      background-color: rgba(255, 255, 255, .5);
    }
    .scrollable-bar:hover, &.active .scrollable-bar {
      opacity:1;
      background-color: rgba(255, 255, 255, 0);
    }
    .scrollable-slider {
      border-radius: 4px;
      background-color: rgba(0,0,0,0);
    }
    .scrollable-bar-x .scrollable-slider {
      height: 48px;
      border: 2px solid $brand-info;
      @include box-shadow(0 0 3px 0 rgba(0,0,0,.375));

      &:hover {
        background-color:rgba(255,255,255,.25);
        @include box-shadow(0 0 6px 0 rgba(0,0,0,.375));      }
    }
    .scrollable-bar-y .scrollable-slider {
      width: 8px;
    }
  }
}

.scrubber {
  margin-top:-50px;
  padding:4px 0;
  .scrubber-row {
    display:flex;
    .spacer {
      flex-grow:12;
    }
    .scrubber-item {
      height:20px;
      flex-grow:1;
      .base {
        height:50%;
        width:100%;
        
        &.base-A {
          background-color: $color-a;
        } 
        &.base-C {
          background-color: $color-c;
        }
        &.base-G {
          background-color: $color-g;
        }
        &.base-T {
          background-color: $color-t;
        }
      }
    }
  }
}

.contributions-chart {
  .wt-icon {
    font-size: 18px;
    line-height: 16px;
  }
  .title {
    @extend .all-caps;
    font-weight:bold;
    color:$gray-darker;
  }
  table.sequences {
    margin-top:15px;
    font-size:12px;
    vertical-align:middle;
    width:100%;
    position:relative;

    &.sequences-multiplex {
      tr.sequence td.readable .readable-item {
        flex-grow:0;
      }
    }

    td {
      color:$gray;
      padding:0 5px;
    }
    thead {
      tr > th {
        @extend .all-caps;
        font-size:11px;
        color:$gray;
        padding:5px;
        white-space:nowrap;
      }
    }
    td, th {
      &.relative {
        width:120px;
      }

      &.indel {
        //max-width:120px;
        white-space:nowrap;
        text-align:right;
        .indel-details {

          .label-light {
            font-size:1em;
            font-weight:normal;
            display:inline-block;
            min-width:50px;
            text-align:left;
            padding:.2em .4em .4em;
            margin-right:.1em;
            color:$gray;
            background:$gray-lighter;
          }
        }
        .indel-total {
          display:inline-block;
          min-width:30px;
          font-weight:bold;
        }
      }

      &.copy {
        width:18px;
      }
    }
    tr.sequence {
      transition:transform .1s linear;

      /*&.ng-enter {
        transform:rotateX(90deg);
      }
      &.ng-enter-active {
        transform:rotateX(0deg);
      }
      &.ng-enter-stagger {
        transition-delay: .05s;
        transition-duration: 0s;
      }*/

      &.sequence-knockin {
        &:after {
          content:'';
          display:block;
          width:calc(100% - 33px);
          border:1px solid $brand-warning;
          height:24px;
          position:absolute;
          margin-top:-24px;
          left:33px;
          border-radius:3px;
          pointer-events:none;
        }
        .indel {
          text-align:left;
          .label-knockin {
            height:22px;
            position: relative;
            border-top-right-radius:0;
            border-bottom-right-radius:0;
            display:inline-flex;
            align-items:center;
          }
        }
      }

      &:hover {
        background:#f8f8f8;

        td.readable .readable-item .base {
          &.base-A {
            background-color: $color-a-light;
          } 
          &.base-C {
            background-color: $color-c-light;
          }
          &.base-G {
            background-color: $color-g-light;
          }
          &.base-T {
            background-color: $color-t-light;
          }
          &.base-a, &.base-c, &.base-g, &.base-t {
            background-color: $color-inserted-light;
          }  
        }
        .btn-hover {
          visibility:visible;
        }
      }

      .btn-hover {
        visibility:hidden;
        padding:0;
      }
      td {
        &.wt {
          max-width:24px;
        }
        &.relative {

          .relative-bar {
            display:flex;
            align-items:center;
            justify-content:space-between;

            .bar {
              flex-grow:1;
              .bar-inner {
                background:$brand-info;
                height:4px;
                border-radius:$border-radius-base;
              }
            }
          }
        }
        &.readable {
          display:flex;
          flex-grow:1;

          .readable-item {
            @extend .mono;
            //width:1.3%;
            transition:all .3s ease-in-out;
            flex-shrink:0;
            flex-grow:1;

            .base {
              padding:3px 1px;
              @include base;
            }

            .cutsite-line {
              border-left:2px dashed $gray;
              height:17px;
              margin:0 5px -5px 5px;
            }

          }
        }
      }
      &:first-child {
        .cutsite-line {
          position:relative;
          &:before {
            @extend .all-caps;
            font-family:$font-family-base;
            font-size:11px;
            content:"Cut site";
            position:absolute;
            white-space:nowrap;
            top:-19px;
            left:-2px;
            border-left:2px dashed $gray;
            padding-left:5px;
          }
        }
      }
    }
  }
}

.powered-by {
  position:relative;
  padding-bottom:10px;
  img {
    position:absolute;
    top:-10px;
    right:0;
    width:215px;
  }
}

.table-samples-detail {
  table-layout:fixed;
  tbody > tr > td,
  thead > tr > th {
    overflow: hidden;
    text-overflow: ellipsis;

    &.name {
      width:25%;
      > span {
        display:flex;
        align-items:center;
      }
      i {
        margin-right:10px;
      }
      button {
        margin-left:auto;
      }
    }
    &.ice,
    &.ice-d,
    &.rsq {
      width:8.3%;
      text-align:center;
      .label {
        font-size:14px;
      }
    }
    &.guide {
      width:20%;
    }
    &.pam {
      width:10%;
    }
    &.nuclease,
    &.actions {
      width:13%;
      overflow:visible;
    }
  }
  tbody > tr:hover {
    td.name strong {
      text-decoration:underline;
    }
  }
}