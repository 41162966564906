.c3-grid {
  line {
    stroke: #aaa;
  }
  text {
    fill: #aaa;
  }

  .cut-site {
    opacity:0.6;
    text {
      fill:#000000;
    }
    line {
      stroke:#000000;
      stroke-dasharray:3;
    }
  }

  .guide {
    text {
      fill:#D6C600;
    }
    line {
      stroke:#D6C600;
    }
  }
  
  .alignment {
    opacity:.5;
    text {
      fill:$brand-danger;
    }
    line {
      stroke:$brand-danger;
    }
  }

  .inference {
    opacity:.5;
    text {
      fill:$brand-warning;
    }
    line {
      stroke:$brand-warning;
    }
  }
}
.c3-xgrid, .c3-ygrid {
  stroke-dasharray: 3 3;
}
.c3-xgrid-focus {
}
