.file-drop {
  height:150px;
  border:2px dashed #fff;
  background:rgba(255,255,255,.05);
  padding:30px;
  text-align:center;
  strong {
    color:#fff;
    font-weight:normal;
    font-size:20px;
    display:block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  small {
    font-size:14px;
    color:rgba(255,255,255,.5);
    em {
      font-style:normal;
      text-decoration:underline;
      cursor:pointer;
    }
  }
  svg {
    height:36px;
  }

  &:hover {
    background:rgba(255,255,255,.1);
  }

  &.disabled,
  fieldset[disabled] & {
    pointer-events:none;
    opacity:.75;
  }

  &.dragover {
    border-color:$brand-success;
    background:rgba($brand-success, .15);
    svg {
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-name: bounce;
    }
  }

  &.dragover-error {
    border-color:$brand-danger;
    background:rgba($brand-danger, .15);
    svg {
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-name: shake;
    }
  }

  &.ng-valid:not(.dragover-error) {
    color:$brand-success;
    border-color:$brand-success;
    svg {
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-name: pulse;
      path {
        fill:$brand-success;
      }
    }
  }
  &.ng-dirty.ng-invalid {
    color:$brand-danger;
    border-color:$brand-danger;
    svg path {
      fill:$brand-danger;
    }
  }
}